<template>
  <v-container fluid class="main-layout">
    <v-row no-gutters>
      <v-col lg="6" v-show="$vuetify.breakpoint.mdAndUp">
        <registration-cover />
      </v-col>

      <v-col col="6" class="form-col">
        <div class="form-col-content">
          <div class="form-col2-container">
            <form novalidate @submit.prevent="save" class="top-section">
              <div class="title">Welcome!</div>
              <div class="subtitle">Create Account here:</div>
              <div class="center">
                <div class="md-layout md-gutter md-alignment-center">
                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('firstName')"
                      class="field-margin"
                      id="first-name"
                    >
                      <div class="input">
                        <input
                          class="input-width"
                          name="firstName"
                          v-focus
                          placeholder="First Name"
                          v-model="model.firstName"
                          :disabled="saving"
                        />
                      </div>
                      <span class="md-error" v-if="firstNameValidationError">{{
                        firstNameValidationError
                      }}</span>
                    </md-field>
                  </div>

                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('lastName')"
                      class="field-margin last_name"
                      id="last-name"
                    >
                      <div class="input">
                        <input
                          placeholder="Last Name"
                          class="input-width"
                          name="lastName"
                          v-model="model.lastName"
                          :disabled="saving"
                        />
                      </div>
                      <span class="md-error" v-if="lastNameValidationError">{{
                        lastNameValidationError
                      }}</span>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout email-field-width">
                  <div class="md-layout-item item-size-100">
                    <md-field
                      :class="getValidationClass('email')"
                      class="email-field"
                      id="email"
                    >
                      <div class="email-input">
                        <input
                          placeholder="Email"
                          class="input-width"
                          type="email"
                          name="email"
                          autocomplete="email"
                          v-model="model.email"
                          :disabled="saving"
                        />
                      </div>
                      <span class="md-error" v-if="emailValidationError">{{
                        emailValidationError
                      }}</span>
                    </md-field>
                  </div>
                </div>

                <div class="md-layout email-field-width">
                  <div class="md-layout-item item-size-100">
                    <button
                      style="display: none"
                      id="dummy-button-to-unfocus-dropdown-field"
                    />
                    <md-field
                      :class="getValidationClass('organizationRegistry')"
                      class="email-field"
                      id="organization-registry"
                    >
                      <md-select
                        v-model="model.organizationRegistry"
                        name="organization-registry"
                        class="input input-width select-registry"
                        placeholder="Choose Your Business Name"
                      >
                        <v-text-field
                          class="search-width"
                          label="Search"
                          v-on:input="debounceSearch"
                          name="search"
                          v-model="searchName"
                          clearable
                        />
                        <global-registry-dropdown
                          :globalRegistryList="globalRegistryList"
                          :searchName="searchName"
                          @click="handleClick"
                          @fetch-more="fetchMore"
                        />
                      </md-select>
                      <div>
                        <organization-request
                          class="account-request-container"
                          :active="organizationRequesting"
                          @close="organizationRequesting = false"
                        />
                      </div>
                      <span
                        class="md-error"
                        v-if="organizationRegistryValidationError"
                        >{{ organizationRegistryValidationError }}</span
                      >
                    </md-field>
                  </div>
                </div>
                <password-fields
                  :model="model"
                  :saving="saving"
                  :passwordValidationError="passwordValidationError"
                  :confirmPasswordValidationError="
                    confirmPasswordValidationError
                  "
                  :getValidationClass="getValidationClass"
                  @update:password="model.password = $event"
                  @update:confirmPassword="model.confirmPassword = $event"
                />
                <div class="terms-style">
                  <v-checkbox
                    v-model="model.terms"
                    :error-messages="checkboxErrors"
                    required
                    @change="$v.model.terms.$touch()"
                    @blur="$v.model.terms.$touch()"
                    color="#473068"
                  >
                    <template v-slot:label>
                      <span id="checkboxLabel">
                        I accept the{{ " "
                        }}<a
                          href="#"
                          @click.stop="openTerms"
                          class="login-link"
                          target="_blank"
                          >terms and conditions</a
                        >,{{ " "
                        }}<a
                          href="#"
                          @click.stop="openCodeOfConduct"
                          class="login-link"
                          target="_blank"
                          >code of conduct</a
                        >{{ " " }}and{{ " "
                        }}<a
                          href="#"
                          @click.stop="openPrivacyPolicy"
                          class="login-link"
                          target="_blank"
                          >privacy policy</a
                        >.
                      </span>
                    </template>
                  </v-checkbox>
                </div>
                <div v-if="addRecaptcha" class="recaptcha">
                  <md-card-actions>
                    <vue-recaptcha
                      :sitekey="conf.captchaKey"
                      :loadRecaptchaScript="true"
                      ref="recaptcha"
                      @verify="onCaptchaVerified"
                      @expired="onExpired"
                    >
                    </vue-recaptcha>
                  </md-card-actions>
                  <span class="md-error center" v-if="recaptchaError">{{
                    recaptchaError
                  }}</span>
                </div>

                <md-button
                  type="submit"
                  class="wide-button register-button"
                  :disabled="saving"
                  id="register"
                >
                  <div class="signup-text">Sign Up</div>
                </md-button>

                <md-button
                  v-if="ssoEnabled"
                  class="margin-s wide-button sign-with-google"
                  @click.prevent="authProvider('google')"
                  ><img src="../../assets/google_icon.png" width="20px" /> SIGN
                  UP WITH GOOGLE
                </md-button>
                <div class="md-layout bottom-section">
                  <div class="md-layout-item md-size-100">
                    <div class="already-registered-section">
                      <div class="login">
                        Already Registered?
                        <a href="#/login" class="login-link">Log In</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import validationMixin from "../../validation/validation_mixin";
import debounce from "lodash/debounce";
import {
  required,
  email,
  maxLength,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import validation from "../../validation";
import auth from "@/auth";
import * as conf from "../../conf.yml";
import {
  confirmBox,
  resetLeaveAlert,
  setLeaveAlert,
} from "../../utils/confirmBox";
import VueRecaptcha from "vue-recaptcha";
import RegistrationCover from "../../components/RegistrationCover.vue";
import GlobalRegistryDropdown from "./GlobalRegistryDropdown.vue";
import PasswordFields from "./PasswordFields.vue";
import { StatusCodes } from "http-status-codes";
import { ORGANIZATION_TYPES } from "@/utils/constants";

const validatePassword = (value) => {
  if (!value) {
    return true;
  }

  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(value);
};

export default {
  name: "userRegistration",
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
    RegistrationCover,
    GlobalRegistryDropdown,
    PasswordFields,
  },

  data() {
    return {
      logoImg: require(`../../assets/seamless-source-banner.png`),
      globalRegistryList: [],
      searchName: "",
      currentPage: 1,
      model: {
        firstName: null,
        lastName: null,
        organizationRegistry: null,
        email: null,
        password: null,
        confirmPassword: null,
        terms: false,
      },
      recaptcha: null,
      recaptchaError: "",
      conf: conf,
      addRecaptcha: false,
      saving: false,
      ORGANIZATION_TYPES,
      showDialog: false,
      organizationRequesting: false,
      message: null,
      profileUrl: require(`../../assets/person.webp`),
      ignoreEditForm: false,
    };
  },

  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  validations: {
    model: {
      firstName: {
        required,
        maxLength: maxLength(150),
        minLength: minLength(3),
        pattern: (value) => /^[a-zA-Z0-9_\s]+$/.test(value),
      },
      lastName: {
        required,
        maxLength: maxLength(150),
        minLength: minLength(3),
        pattern: (value) => /^[a-zA-Z0-9_\s]+$/.test(value),
      },
      organizationRegistry: {
        required,
      },
      email: {
        required,
        email: (val) => email(validation.emailFormatter(val)),
        maxLength: maxLength(255),
      },
      password: {
        required,
        validatePassword,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      terms: {
        checked: sameAs(() => true),
      },
    },
  },
  watch: {
    model: {
      handler(value) {
        this.ignoreEditForm = setLeaveAlert(this.ignoreEditForm);
      },
      deep: true,
    },
  },
  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.model.terms.$dirty) return errors;
      if (!this.$v.model.terms.checked && this.save) {
        errors.push("You must accept the terms and conditions");
      }
      return errors;
    },
    organizationType() {
      return ORGANIZATION_TYPES;
    },
    firstNameValidationError() {
      if (!this.$v.model.firstName.required) {
        return "First name is required";
      }

      if (!this.$v.model.firstName.minLength) {
        return "First name must have at least 3 characters";
      }

      if (!this.$v.model.firstName.maxLength) {
        return "First name should be less than 150 characters";
      }

      if (!this.$v.model.firstName.pattern) {
        return "First name should contain only letters, numbers and underscores";
      }

      return null;
    },
    lastNameValidationError() {
      if (!this.$v.model.lastName.required) {
        return "Last name is required";
      }

      if (!this.$v.model.lastName.minLength) {
        return "Last name must have at least 3 characters";
      }

      if (!this.$v.model.lastName.maxLength) {
        return "Last name should be less than 150 characters";
      }

      if (!this.$v.model.lastName.pattern) {
        return "Last name should contain only letters, numbers and underscores";
      }

      return null;
    },
    organizationRegistryValidationError() {
      if (!this.$v.model.organizationRegistry.required) {
        return "Business name is required";
      }
      return null;
    },
    emailValidationError() {
      if (!this.$v.model.email.required) {
        return "The email is required";
      }

      if (!this.$v.model.email.email) {
        return "Invalid email";
      }

      if (!this.$v.model.email.maxLength) {
        return "Email should be less than 255 characters";
      }

      return null;
    },
    passwordValidationError() {
      if (!this.$v.model.password.required) {
        return "The password is required";
      }

      if (!this.$v.model.password.validatePassword) {
        return "Please add a strong password";
      }

      return null;
    },
    confirmPasswordValidationError() {
      if (!this.$v.model.confirmPassword.required) {
        return "The confirm password is required";
      }

      if (!this.$v.model.confirmPassword.sameAsPassword) {
        return "Password and confirm password should be identical";
      }

      return null;
    },
    termsValidationError() {
      if (!this.$v.model.terms.checked) {
        return "Please accept the terms and conditions";
      }

      return null;
    },

    ssoEnabled() {
      return conf.sso === true;
    },
  },
  mounted() {
    this.addRecaptcha = conf.addCaptcha;
  },
  methods: {
    openTerms() {
      window.open("https://seamlesssource.com/code-of-conduct-2", "_blank");
    },
    openPrivacyPolicy() {
      window.open("https://seamlesssource.com/privacy-policy", "_blank");
    },
    openCodeOfConduct() {
      window.open("https://seamlesssource.com/code-of-conduct", "_blank");
    },
    onCaptchaVerified(response) {
      this.recaptcha = response;
      this.recaptchaError = "";
    },
    onExpired() {
      this.recaptcha = null;
      this.recaptchaError = "";
    },
    getGlobalRegistryList() {
      this.globalRegistryList = [];
      this.currentPage = 1;
      let url = "/api/organization_registry";
      url +=
        "?page=" +
        this.currentPage +
        "&name=" +
        (this.searchName ? this.searchName : "") +
        "&from_registration_page=true";
      restAdapter.get(url).then((response) => {
        this.globalRegistryList = response.data.data;
      });
    },

    fetchMore($state) {
      let url = "/api/organization_registry";
      url +=
        "?page=" +
        (this.currentPage + 1) +
        "&name=" +
        (this.searchName ? this.searchName : "") +
        "&from_registration_page=true";
      restAdapter.get(url).then((response) => {
        if (response.data.data.length) {
          this.globalRegistryList = this.globalRegistryList.concat(
            response.data.data
          );
          this.currentPage++;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },

    openOrganizationRequest() {
      this.organizationRequesting = true;
    },
    handleClick() {
      document.getElementById("dummy-button-to-unfocus-dropdown-field").click();
      this.openOrganizationRequest();
    },

    clearForm() {
      this.$v.$reset();
      this.model.firstName = null;
      this.model.lastName = null;
      this.model.organizationRegistry = null;
      this.model.email = null;
      this.model.password = null;
      this.model.confirmPassword = null;
      if (this.addRecaptcha) {
        this.recaptcha = null;
        this.$refs.recaptcha.reset();
      }
      this.ignoreEditForm = true;
    },

    debounceSearch: debounce(function () {
      this.getGlobalRegistryList();
    }, 600),

    select(id) {
      if (id === 100) {
        return true;
      }

      return false;
    },
    save() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (!this.recaptcha && this.addRecaptcha) {
        this.recaptchaError = "Please select the captcha";
        return;
      }

      this.saving = true;

      const formData = new FormData();
      formData.append("first_name", this.model.firstName);
      formData.append("last_name", this.model.lastName);
      formData.append(
        "organization_registry_id",
        this.model.organizationRegistry
      );
      formData.append("email", this.model.email);
      formData.append("password", this.model.password);
      formData.append("confirm_password", this.model.confirmPassword);
      formData.append("terms_and_conditions", this.model.terms);
      formData.append("recaptcha", this.recaptcha);

      restAdapter
        .post("/api/register", formData)
        .then((response) => {
          const notify = "Please verify your email before login!";
          notification.successWithAlert(
            "You have registered successfully",
            notify
          );

          this.clearForm();
          resetLeaveAlert();
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          this.saving = false;
          if (
            error.response &&
            error.response.status === StatusCodes.BAD_REQUEST &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later");
          }
        });
    },
    authProvider(provider) {
      const leaveGranted = confirmBox(this.$store.state.routeLeaveAlert);
      if (!leaveGranted) {
        return;
      }
      resetLeaveAlert();
      this.$auth
        .authenticate(provider)
        .then((response) => {
          this.socialLogin(provider, response);
        })
        .catch((err) => {
          if (err.error) {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },

    socialLogin(provider, response) {
      auth
        .extractSocialDetails(provider, response)
        .then((response) => {
          if (response.data.already_registered) {
            notification.success("Welcome to " + conf.appName);
            this.$router.push(this.$route.query.redirect || "/app");
            window.location.reload(true);
          } else {
            this.$router.push({ path: "/complete_profile" });
          }
        })
        .catch((err) => {
          notification.error("Something went wrong, please try again later");
        });
    },
  },

  beforeRouteLeave(to, from, next) {
    confirmBox(this.$store.state.routeLeaveAlert, next);
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/Register.scss";
</style>
<style>
.md-menu-content-container {
  overflow: unset !important;
  width: 100%;
}
</style>
